import React from 'react';

import { ComparisonSection } from '@lerna-monorepo/common/components/comparisonSection/comparisonSection.component';

import { MachineComparisonPageData } from './machineComparison.types';

const MachineComparisonPage: React.FC<MachineComparisonPageData> = (pageData: MachineComparisonPageData) => {
  const { data, products, currentLanguage } = pageData.pageContext;
  const { header, description, selectPlaceholder, comparisonClearButtonLabel } = data.machineComparisonSection;

  return (
    <ComparisonSection
      header={header}
      description={description}
      products={products}
      selectPlaceholder={selectPlaceholder}
      comparisonClearButtonLabel={comparisonClearButtonLabel}
      currentLanguage={currentLanguage}
    />
  );
};

export default MachineComparisonPage;
